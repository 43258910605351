/*
  COMPONENT: Button
*/
.button {
  background: var(--normal);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  cursor: pointer;
  transition-duration: .3s;
  user-select: none;
  border: none;
  outline: 2px solid transparent;
}
.button:disabled {
  opacity: .5;
  cursor: not-allowed;
}
.button:focus-visible {
  outline-color: var(--blue);
}
.button--content {
  display: flex;
  gap: 25px;
  align-items: center;
}
/*
  Part: Decoration (Optional)
*/
.button__circle, .button__load {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition-duration: .3s;
}
.button__load {
  scale: 1.6;
  animation: animation__button__load 1s infinite;
  z-index: 3;
}
.button__load::before {
  content: "";
  position: absolute; 
  inset: -1px;
  border-radius: 50%;
  background: conic-gradient(var(--normal) 90deg, transparent 0deg);
}
.button__load::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  border-radius: 50%;
  background: var(--normal);
}
@keyframes animation__button__load {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*
  Part: Context
*/
.button--text {
  margin-block: 10px;
  display: flex;
  flex-direction: column;
}
.button--title {
  display: flex;
  align-items: center;
  gap: 5px;
}
.button--note {
  font-size: 12px;
  color: var(--gray);
}
.button--description {
  font-size: 10px;
  color: var(--gray);
  text-align: left;
}
/*
  Part: Action
*/
.button--action {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  border-radius: 5px;
  transition-duration: .3s;
}
.button--action--icon {
  width: 24px;
  stroke: var(--white);
}