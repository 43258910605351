:root {
    --blue: #1a73e8;
    --red: #ea4335;
    --green: #34a853;
  
    --black: #1a1625;
    --dark: #2f2b3a;
    --normal: #46424f;
    --light: #5e5a66;
    --bright: #76737e;
    --brighter: #908d96;
    --white: #ffffff;
  
    --gray: #bdbdbd;
  }
  
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: var(--white);
}

body {
  background: var(--dark);
  position: relative;
}

.blur {
  background: rgb(113,113,113);
  background: radial-gradient(circle, rgba(221,221,221,0.1) 30%, rgba(113,113,113,0.1) 90%);
  position: absolute;
  inset: 0;
}
.blur h1 {
  position: absolute;
  top: 250px;
  left: 50%;
  translate: -50% -50%;
  background: #00000048;
  border-radius: 15px;
  padding: 50px 30px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: var(--white);
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  grid-gap: 10px;
}
.slot {
  background: var(--light);
  transition-duration: .3s;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: 2px solid transparent;
}
.slot:hover {
  background: var(--bright);
}
.slot:focus-visible {
  outline-color: var(--blue);
}